import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import "./blog.css"
// import Blog from "../../components/blogs"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
// import Layout from "../../../components/layout"

const AccesibilidadP = props => {
  const query = useStaticQuery(graphql`
    query AccesibilidadP {
      footer: allStrapiMenuFooter {
        edges {
          node {
            menu_item_footer {
              Titulo
              Perfiles
              sub_item_footer {
                Titulo
                Link_externo
                pagina {
                  title
                  slug
                  site
                }
              }
            }
          }
        }
      }

      allStrapiMainMenu {
        edges {
          node {
            menu_item {
              title
              site
              page {
                slug
              }
              sub_item {
                title
                page {
                  slug
                  title
                  site
                }
              }
            }
          }
        }
      }
    }
  `)

  const menu = query.allStrapiMainMenu.edges
  const footer = query.footer.edges
  const menu_footer = footer[0].node.menu_item_footer
  let url_global = process.env.GATSBY_API_URL
  let image_url =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />
      <Seo title="Personas" />

      <div
        className="absolute w-screen altura_banner_blog ancho_banner_fondo_blog_sm altura_banner_quienes_somos lg:w-screen altura_banner_quienes_somos posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
        title="valla accesibilidad"
        style={{
          backgroundImage: `${image_url}`,
        }}
      ></div>
      <div
        className="container mx-auto titulo_principal_blog_sm titulo_blog_encabezado_alinear text-poppins-bold lg:relative titulo_ventaja_lg"
        style={{
          width: "95%",
          left: "4rem",
          position: "relative",
          color: "#256238",
        }}
      >
        <p>Accesibilidad</p>
      </div>

      <div
        className="container mx-auto"
        style={{ marginTop: "24rem", marginBottom: "3rem" }}
      >
        <div class="mx-auto inline-grid grid-cols-2 text-center mitexto gap-3">
          <div class="flex text-center flex items-center ">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Imágenes de texto:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                En general, el contenido, excepto logotipos o imágenes de marca,
                se puede configurar, ya que se evitan las imágenes de texto.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Contraste de colores:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                Las características visuales de la web (tipo de letra, color de
                texto y fondo) se han definido mediante la hoja de estilo. Deben
                cumplir las pautas de accesibilidad referentes a la ratio de
                contraste en el nivel AA.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center ">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Formularios:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                Los formularios contienen etiquetas y controles asociados
                correctamente.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Audiovisuales:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                Los audiovisuales de información relevante están subtitulados.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center ">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Reproducción de audiovisuales:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                Los reproductores de audiovisuales son accesibles para usuarios
                de cualquier tipo de dispositivo, usuarios de teclado y personas
                con discapacidad visual.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">
                Redes sociales:
              </p>
              <p className="text-poppins-regular color-texto-parrafo">
                Los conectores de las redes sociales son accesibles y tienen
                alternativa textual.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">Idiomas:</p>
              <p className="text-poppins-regular color-texto-parrafo">
                El idioma de las páginas está marcado correctamente.
              </p>
            </div>
          </div>

          <div class="flex text-center flex items-center">
            <img
              src={url_global + "/uploads/iconos_from_2a40c676b2.png"}
              className="fill-current w-20 h-20 mr-2"
              alt="iconos_from_2a40c676b2.png"
              title="iconos_from_2a40c676b2.png"
            />
            <div className="inline-grid grid-cols-1 text-left">
              <p className="text-poppins-bold color-texto-parrafo">Lenguaje:</p>
              <p className="text-poppins-regular color-texto-parrafo">
                El lenguaje de la página es simple y adecuado para el público al
                que se dirige el contenido.
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <p className="text-poppins-bold color-texto-parrafo mitexto_color">
          Este desarrollo se basa en las consideraciónes y pautas de
          accesibilidad al contenido{" "}
          <a href="https://www.w3.org/TR/WCAG21/" target="_blank">
            WCAG 2.1.
          </a>{" "}
          en el nivel AA, establecidas por el W3C (Consorcio de la World Wide
          Web).
        </p>
      </div>

      <Footer />
    </>
  )
}

AccesibilidadP.propTypes = {
  siteTitle: PropTypes.string,
}

AccesibilidadP.defaultProps = {
  siteTitle: ``,
}

export default AccesibilidadP
